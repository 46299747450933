import React from 'react'

//Libraries
import { Button, Container } from '@hybris-software/ui-kit'

//images
import zenTokenHeroRightSection from '../../../../Assets/images/ZenTokenView/zenTokenHeroRightSection.png'

// Style
import Style from './Hero.module.css'

const Hero = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.wrapper}>
          <div className={Style.textWrapper}>
            <h6 className={Style.heroLineOne}>
              Zen Token Powering the Zenit World Ecosystem
            </h6>
            <h2 className={Style.heroLineTwo}>
              Unlock Exclusive Benefits and Premium Features with Zen Token
            </h2>
            <p className={Style.heroLineThree}>
              Explore the key features and mechanics of ZEN Token, including its
              distribution, token burning, and staking rewards
            </p>
            <a href="https://app.zenit.world/auth/register" target="_blank">
              <Button>Get Started</Button>
            </a>
          </div>
          <div className={Style.imageWrapper}>
            <img
              className={Style.heroImage}
              src={zenTokenHeroRightSection}
              alt="zenToken"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Hero

import { Container } from '@hybris-software/ui-kit'
import React from 'react'

// Style
import Style from './MissionAndVision.module.css'
const MissionAndVision = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.missionAndVision}>
          {/* Vision */}
          <div className={Style.vision}>
            <h3 className={Style.title}>Vision</h3>
            <p className={Style.desc}>
              Empowering individuals worldwide to achieve financial independence
              through the cryptocurrency market.
            </p>
          </div>
          {/* Mission */}
          <div className={Style.mission}>
            <h3 className={Style.title}>Mission</h3>
            <p className={Style.desc}>
              Dedicated to leveling the playing field in the world of crypto
              trading, ensuring that trading strategies and technologies are
              available to everyone.
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default MissionAndVision

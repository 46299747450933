import { Route } from 'react-router-dom'

// Icons
import { AiFillHome } from 'react-icons/ai'

// Layouts
import MainLayout from '../Layouts/MainLayout/MainLayout'

// Main Views
import HomePageView from '../Views/HomePageView'
import AboutUsView from '../Views/AboutUsView'
import RoadMapView from '../Views/RoadMapView'
import CopyTradingView from '../Views/CopyTradingView'
import SwapView from '../Views/SwapView'
import StakingView from '../Views/StakingView'
import ZenTokenView from '../Views/ZenTokenView'
import FaitWalletView from '../Views/FaitWalletView'
import ContactView from '../Views/ContactView'

const routes = {
  main: {
    path: '/',
    element: <MainLayout />,
    title: 'Insert title',
    routes: {
      home: {
        path: '',
        element: <HomePageView />,
        title: 'Home',
        icon: <AiFillHome />,
      },
      aboutUs: {
        path: 'about-us',
        element: <AboutUsView />,
        title: 'About Us',
        icon: <AiFillHome />,
      },
      roadMap: {
        path: 'road-map',
        element: <RoadMapView />,
        title: 'Road Map',
        icon: <AiFillHome />,
      },
      copyTrading: {
        path: 'copy-trading',
        element: <CopyTradingView />,
        title: 'Copy Trading',
        icon: <AiFillHome />,
      },
      swap: {
        path: 'swap',
        element: <SwapView />,
        title: 'Swap',
        icon: <AiFillHome />,
      },
      staking: {
        path: 'staking',
        element: <StakingView />,
        title: 'Staking',
        icon: <AiFillHome />,
      },
      fiatWallet: {
        path: 'fiat-wallet',
        element: <FaitWalletView />,
        title: 'Fiat Wallet',
        icon: <AiFillHome />,
      },
      zenToken: {
        path: 'zen-token',
        element: <ZenTokenView />,
        title: 'Zen Token',
        icon: <AiFillHome />,
      },
      // contact: {
      //   path: 'contact',
      //   element: <ContactView />,
      //   title: 'Contact',
      //   icon: <AiFillHome />,
      // },
    },
  },
}

// function generatePaths(routes, parentPath = "") {
//   const paths = {};
//   Object.entries(routes).forEach(([routeName, route]) => {
//     if (route.routes) {
//       paths[routeName] = generatePaths(route.routes, parentPath + route.path);
//     } else {
//       paths[routeName] = parentPath + route.path;
//     }
//   });
//   return paths;
// }

function generatePaths(routes, parentPath = '') {
  const paths = {}
  Object.entries(routes).forEach(([routeName, route]) => {
    if (route.routes && Object.keys(route.routes).length > 0) {
      paths[routeName] = generatePaths(route.routes, parentPath + route.path)
    } else {
      paths[routeName] = parentPath + route.path
    }
  })
  return paths
}

function generateRouteComponents(routes, parentPath = '') {
  if (parentPath === '') {
    // Is a layout
    return Object.keys(routes).map((routeName, index) => {
      return (
        <Route key={index} element={routes[routeName].element}>
          {generateRouteComponents(
            routes[routeName].routes,
            routes[routeName].path
          )}
        </Route>
      )
    })
  } else {
    // Is a view
    const views = []

    Object.keys(routes).forEach((routeName, index) => {
      views.push(
        <Route
          key={index}
          path={parentPath + routes[routeName].path}
          element={routes[routeName].element}
        />
      )

      if (routes[routeName].routes) {
        views.push(
          generateRouteComponents(
            routes[routeName].routes,
            parentPath + routes[routeName].path
          )
        )
      }
    })
    return views
  }
}

function generateSitemap(routes, parentPath = '') {
  return (
    <ol style={{ marginLeft: '2em' }}>
      {Object.keys(routes).map((routeName, index) => {
        return (
          <li key={index}>
            {parentPath === '' ? (
              routes[routeName].title
            ) : (
              <a href={parentPath + routes[routeName].path}>
                {routes[routeName].title} ({parentPath + routes[routeName].path}
                )
              </a>
            )}

            {routes[routeName].routes &&
              generateSitemap(
                routes[routeName].routes,
                parentPath + routes[routeName].path
              )}
          </li>
        )
      })}
    </ol>
  )
}

const paths = generatePaths(routes)
const routeComponents = generateRouteComponents(routes)
const sitemap = generateSitemap(routes)

export { routes, paths, routeComponents, sitemap }

import React from 'react'

// Libraries

import { Button, Container } from '@hybris-software/ui-kit'

// Images

import HeroBg from '../../../../Assets/images/heroBgSwap.png'
import SwapImage from '../../../../Assets/images/SWAP. SWAP. SWAP.png'

// Icons

import { BiBookAlt } from 'react-icons/bi'

// Style

import Style from './HeroSection.module.css'

const HeroSection = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.heroSection}>
          <div className={Style.textWrapper}>
            <h4 className={Style.miniTitle}>
              With Zenit's Copy Trading feature
            </h4>
            <h3 className={Style.title}>
              Streamlined Crypto Exchanges Made Easy
            </h3>

            <p className={Style.discreption}>
              Effortlessly exchange one cryptocurrency for another with our
              user-friendly swapping feature and enjoy low fees. 
            </p>

            <div className={Style.buttonsWrapper}>
              <a
                href="https://app.zenit.world/auth/login"
                target="_blank"
                rel="noreferrer"
              >
                <Button>Start Swapping</Button>
              </a>
              <a
                href="https://docs.zenit.world/about/white-paper"
                target="_blank"
                rel="noreferrer"
              >
                <p className={Style.videoButton}>
                  <span className={Style.iconWrapper}>
                    <BiBookAlt className={Style.icon} />
                  </span>
                  Read Our Whitepaper
                </p>
              </a>
            </div>
          </div>

          <div className={Style.imageWrapper}>
            <img src={HeroBg} alt="" />
          </div>
        </div>
      </Container>
      <div className={Style.swapImageWrapper}>
        <img src={SwapImage} alt="" />
      </div>
    </div>
  )
}

export default HeroSection

import React from 'react'

//  Library
import { Container } from '@hybris-software/ui-kit'
// Icons
import { AiOutlineInstagram } from 'react-icons/ai'
import { FaFacebookF } from 'react-icons/fa'
import { AiOutlineTwitter } from 'react-icons/ai'
import { AiFillYoutube } from 'react-icons/ai'
import { BiLogoLinkedin } from 'react-icons/bi'
import { BsMedium } from 'react-icons/bs'
import { BsDiscord } from 'react-icons/bs'
import { FaRedditAlien } from 'react-icons/fa'

import { FiArrowUpRight } from 'react-icons/fi'

// Images
import Logo from '../../../Assets/icons/Zenit World_Dark & White Horizontal.svg'

// Style
import Style from './Footer.module.css'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <footer className={Style.footerWrapper}>
      <div className={Style.footer}>
        <Container>
          <div className={Style.footerTop}>
            <div className={Style.logoWrapper}>
              <img src={Logo} alt="" className={Style.logoImage} />
              <div className={Style.contactInfo}>
                <p>Zenit World SRL</p>
                <p>Incorporation Number: 3102876139</p>
                <p>
                  SAN JOSE-ESCAZU SAN RAFAEL, DEL CENTRO COMERCIAL LA PACO,
                  TRESCIENTOS METROS NORTE, PLAZA FLORENCIA
                </p>
                <p>Costa Rica</p>
                <a href="mailto:info@zenit.world">info@zenit.world</a>
              </div>

              <a
                rel="noreferrer"
                href="https://app.zenit.world/auth/register"
                target="_blank"
                className={Style.signinLink}
              >
                <p className={Style.signinLinkText}>SIGN UP</p>
                <FiArrowUpRight className={Style.signinLinkIcon} />
              </a>
            </div>
            <div className={Style.linksWrapper}>
              <div className={Style.links}>
                <div className={Style.moreWrapper}>
                  <h3 className={Style.linkTitle}>Services</h3>
                  <Link to="/copy-trading">Copy Trading</Link>
                  <Link to="/fiat-wallet">Fait Wallet</Link>
                  <Link to="/swap">Swap</Link>
                  <Link to="/staking">Staking</Link>
                  <Link to="/zen-token">ZEN TOKEN</Link>
                </div>
                <div className={Style.moreWrapper}>
                  <h3 className={Style.linkTitle}>Resources</h3>
                  <a
                    href="https://docs.zenit.world/about/white-paper"
                    rel="noreferrer"
                    target="_blank"
                  >
                    White Paper
                  </a>
                  <a
                    href="https://support.zenit.world/support/home"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Customer Support
                  </a>
                  <a
                    href="https://docs.zenit.world"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Zenit World Docs
                  </a>
                  <a
                    href="https://medium.com/@zenitworldofficial"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Blog
                  </a>
                  <a
                    href="https://docs.zenit.world/help-center/introduction-faqs"
                    rel="noreferrer"
                    target="_blank"
                  >
                    FAQ
                  </a>
                  <a
                    href="https://linktr.ee/zenitworld"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Linktr.ee
                  </a>
                </div>
                <div className={Style.moreWrapper}>
                  <h3 className={Style.linkTitle}>Legal</h3>
                  <a
                    href="https://docs.zenit.world/legal/terms-and-conditions"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Terms
                  </a>
                  <a
                    href="https://docs.zenit.world/legal/privacy-policy"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Privacy
                  </a>{' '}
                  <a
                    href="https://docs.zenit.world/legal/cookie-policy"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Cookies
                  </a>{' '}
                  <a
                    href="https://docs.zenit.world/legal/kyc-and-aml"
                    rel="noreferrer"
                    target="_blank"
                  >
                    KYC & AML
                  </a>
                </div>
                <div className={Style.contactWrapper}>
                  <div className={Style.contact}>
                    <h3 className={Style.linkTitle}>Community</h3>
                  </div>
                  <div className={Style.social}>
                    <a
                      href="https://www.instagram.com/zenitworld_/"
                      rel="noreferrer"
                      target="_blank"
                      className={Style.socialIcon}
                    >
                      <AiOutlineInstagram className={Style.icon} />
                    </a>
                    <a
                      href="https://www.facebook.com/ZenitWorld"
                      rel="noreferrer"
                      target="_blank"
                      className={Style.socialIcon}
                    >
                      <FaFacebookF className={Style.icon} />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/zenitworld/"
                      rel="noreferrer"
                      target="_blank"
                      className={Style.socialIcon}
                    >
                      <BiLogoLinkedin className={Style.icon} />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCHBp8Asfh6JtWpm2kmcUA2g"
                      rel="noreferrer"
                      target="_blank"
                      className={Style.socialIcon}
                    >
                      <AiFillYoutube className={Style.icon} />
                    </a>
                    <a
                      href="https://twitter.com/zenitworld_"
                      rel="noreferrer"
                      target="_blank"
                      className={Style.socialIcon}
                    >
                      <AiOutlineTwitter className={Style.icon} />
                    </a>
                    <a
                      href="https://medium.com/@zenitworldofficial"
                      rel="noreferrer"
                      target="_blank"
                      className={Style.socialIcon}
                    >
                      <BsMedium className={Style.icon} />
                    </a>
                    <a
                      href="https://t.me/zenitworldofficial"
                      rel="noreferrer"
                      target="_blank"
                      className={Style.socialIcon}
                    >
                      <BsDiscord className={Style.icon} />
                    </a>
                    <a
                      href="https://www.reddit.com/user/zenitonreddit"
                      rel="noreferrer"
                      target="_blank"
                      className={Style.socialIcon}
                    >
                      <FaRedditAlien className={Style.icon} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={Style.information}>
            <p className={Style.informationText}>
              Disclaimer The information provided on this website does not
              constitute financial advice and should not be relied upon as such.
              Please consult with a professional financial advisor before making
              any investment decisions.
            </p>
          </div>
          <div className={Style.devider} />

          <div className={Style.footerBottomWrapper}>
            <div className={Style.footerBottom}>
              <h2 className={Style.textFooter}>
                © 2023 Zenit World. All rights reserved.
              </h2>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer

import React, { useState } from 'react'

//Libraries
import { Container, useWindowSize } from '@hybris-software/ui-kit'
import { Link } from 'react-router-dom'
// import { useLocation } from 'react-router-dom'

// Paths
import { paths } from '../../../Data/routes'

// Icons
import { IoIosArrowDown } from 'react-icons/io'
import copyIcon from '../../../Assets/icons/copyIcon.svg'
import swapIcon from '../../../Assets/icons/swapIcon.svg'
// import zenIcon from '../../../Assets/icons/zenIcon.svg'
import stakingIcon from '../../../Assets/icons/stakingIcon.svg'
import fiaticon from '../../../Assets/icons/fiatIcon.svg'

// Logo
import logo from '../../../Assets/icons/Zenit World_Dark & White Horizontal.svg'

// Styles
import Style from './NavBar.module.css'

const productLinksData = [
  {
    icon: copyIcon,
    title: 'Copy Trading',
    link: 'copy-trading',
  },
  {
    icon: swapIcon,
    title: 'Swap',
    link: '/swap',
  },
  {
    icon: stakingIcon,
    title: 'Staking',
    link: '/staking',
  },
  {
    icon: fiaticon,
    title: 'Fiat Wallet',
    link: 'fiat-wallet',
  },
]

const NavBar = () => {
  const { width } = useWindowSize()

  return <header>{width > 991 ? <DeskTopNav /> : <MobileNav />}</header>
}

const DeskTopNav = () => {
  return (
    <nav className={Style.nva}>
      <Container>
        <div className={Style.navbar}>
          <div className={Style.links}>
            <Link to={paths.main.home}>
              <img src={logo} alt="" />
            </Link>
            <div className={Style.separator} />
            <div className={Style.product}>
              <h6>
                Product <IoIosArrowDown className={Style.dropDownIcon} />
              </h6>
              <div className={Style.productLinks}>
                {productLinksData.map((item, index) => {
                  return (
                    <div key={index} className={Style.productLink}>
                      <img src={item.icon} alt="copyIcon" />
                      <Link to={item.link}>{item.title}</Link>
                    </div>
                  )
                })}
              </div>
            </div>
            <Link to={paths.main.zenToken}>ZEN Token</Link>
            {/* <Link to={paths.main.roadMap}>Roadmap</Link> */}
            <Link to={paths.main.aboutUs}>About Us</Link>
            {/* <Link to={paths.main.contact}>Contact</Link> */}
          </div>
          <div className={Style.authButtons}>
            <a
              href="https://app.zenit.world/auth/login"
              rel="noreferrer"
              target="_blank"
            >
              Login
            </a>
            {/* TODO: change this signup to a button when the button is configured  */}
            <a
              href="https://app.zenit.world/auth/register"
              rel="noreferrer"
              target="_blank"
              className={Style.loginButton}
            >
              Sign Up
            </a>
          </div>
        </div>
      </Container>
    </nav>
  )
}

const MobileNav = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [dropDown, setDropDown] = useState(false)

  if (menuOpen) document.body.style.overflow = 'hidden'
  else document.body.style.overflow = 'unset'

  return (
    <nav>
      <div className={Style.navbar}>
        <div
          className={menuOpen ? Style.navWrapperOpen : Style.navWrapperClosed}
        >
          <div className={Style.navbarMobile}>
            <Container>
              <div className={Style.mobileNavHeader}>
                <Link onClick={() => setMenuOpen(false)} to={paths.main.home}>
                  <img src={logo} alt="" className={Style.logoImage} />
                </Link>
                <div
                  className={menuOpen ? Style.hamburgerActive : Style.hamburger}
                  onClick={() => {
                    setMenuOpen((old) => !old)
                  }}
                >
                  {/* <div
                  className={menuOpen ? Style.hamburgerActive : Style.hamburger}
                  onClick={() => {
                    setMenuOpen((old) => !old)
                    document.documentElement.scrollTop = 0
                  }}
                ></div> */}
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </Container>

            {menuOpen && (
              <div className={Style.mobileLinksWrapper}>
                <div className={Style.mobileLinks}>
                  <div className={Style.Mobileproduct}>
                    <h6
                      onClick={() => setDropDown(!dropDown)}
                      className={`${
                        dropDown
                          ? Style.productMobileDisActive
                          : Style.productMobileActive
                      }`}
                    >
                      Product <IoIosArrowDown className={Style.dropDownIcon} />
                    </h6>
                    <div
                      className={`${
                        dropDown
                          ? Style.productNoLinksWrapper
                          : Style.productLinksWrapper
                      }`}
                    >
                      {productLinksData.map((item, index) => {
                        return (
                          <div key={index} className={Style.productLinksMobile}>
                            <Link
                              to={item.link}
                              onClick={() => setMenuOpen(false)}
                            >
                              <p>{item.title}</p>
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <Link
                    onClick={() => setMenuOpen(false)}
                    to={paths.main.zenToken}
                  >
                    ZEN Token
                  </Link>
                  {/* <Link
                  onClick={() => setMenuOpen(false)}
                  to={paths.main.roadMap}
                >
                  Roadmap
                </Link> */}
                  <Link
                    onClick={() => setMenuOpen(false)}
                    to={paths.main.aboutUs}
                  >
                    About Us
                  </Link>
                  {/* <Link
                    onClick={() => setMenuOpen(false)}
                    to={paths.main.contact}
                  >
                    Contact
                  </Link> */}

                  <a
                    className={Style.loginButton}
                    href="https://app.zenit.world/auth/login"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Login
                  </a>
                  <a
                    className={Style.loginButton}
                    href="https://app.zenit.world/auth/register"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavBar

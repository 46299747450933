import React from 'react'

//Libraries
import { Button, Col, Container, Row } from '@hybris-software/ui-kit'

//Icons
import { BiBookAlt } from 'react-icons/bi'

//Utils
import classNames from '../../../../Utils/classNames'

// Style
import Style from './Hero.module.css'
import Theme from '../../../../Assets/css/Theme.module.css'

const Hero = () => {
  return (
    <Container>
      <div className={Style.hero}>
        <div className={Style.heroContent}>
          <h6 className={Style.subtitle}>
            connect traditional finance with the crypto world
          </h6>
          <h2 className={Style.title}>
            using our fiat feature, allowing for easy deposit and withdrawal of
            funds.
          </h2>
          <p className={Style.content}>
            Unlock the power of fiat! Zenit's Fiat Wallet feature allows you to
            deposit and withdraw traditional currencies like USD and EUR, while
            seamlessly converting between fiat and cryptocurrencies
          </p>
          <div className={Style.heroAction}>
            <a
              href="https://app.zenit.world/auth/register"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Get Started</Button>
            </a>
            <a
              href="https://docs.zenit.world/about/white-paper"
              target="_blank"
              rel="noreferrer"
            >
              <p className={Style.videoButton}>
                <span className={Style.iconWrapper}>
                  <BiBookAlt className={Style.icon} />
                </span>
                Read Our Whitepaper
              </p>
            </a>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Hero

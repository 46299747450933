import React from 'react'
// Library
import { Container } from '@hybris-software/ui-kit'

// Images
import ZenitLogo from '../../../../Assets/images/zenitLogo3d.png'
// Style
import Style from './HeroSectoin.module.css'
const HeroSectoin = () => {
  return (
    <div className={Style.wrapper}>
      <div className={Style.topCoin} />
      <Container>
        <div className={Style.heroSetion}>
          <h3 className={Style.title}>
            At Zenit World, we are more than just an exchange.
          </h3>
          <p className={Style.desc}>
            We are a community of passionate and dedicated traders who share a
            common goal - to succeed in the world of crypto trading. Together,
            we believe in the transformative power of cryptocurrencies and their
            potential to reshape the financial landscape.
          </p>
          <img src={ZenitLogo} alt="" />
        </div>
      </Container>
      <div className={Style.bottomCoin} />
    </div>
  )
}

export default HeroSectoin

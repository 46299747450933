import React, { useRef } from 'react'

//Components
import Separator from '../../../../Components/UIKit/Separator/Separator'

//Libraries
import { Button, Col, Container, Row } from '@hybris-software/ui-kit'

//images
import stepOne from '../../../../Assets/images/Staking/stakingStepsOne.png'
import stepTwo from '../../../../Assets/images/Staking/stakingStepsTwo.png'
import stepThree from '../../../../Assets/images/Staking/stakingStepsThree.png'
import monthlySectionImage from '../../../../Assets/images/Staking/stakingMonthlySectionImage.png'
import signUpImage from '../../../../Assets/images/Staking/stakingSignUpSection.png'

//Icon
import { FiArrowUpRight } from 'react-icons/fi'
import { FiChevronDown } from 'react-icons/fi'

//Utils
import classNames from '../../../../Utils/classNames'

// Style
import Style from './Content.module.css'
import Theme from '../../../../Assets/css/Theme.module.css'

import { AiFillAccountBook } from 'react-icons/ai'

const Content = () => {
  const collapseOne = useRef(null)
  const collapseTwo = useRef(null)
  const collapseThree = useRef(null)

  return (
    <Container>
      <div className={Style.content}>
        <div className={Style.contentSection}>
          <UnluckThePotenttail />
        </div>
        <div className={classNames(Theme.firstWrapper, Style.contentSection)}>
          <h2 className={Style.potential}>Lock, Earn, and Withdraw</h2>
          <Row
            columnGap={{
              vertical: {
                md: 40,
                sm: 40,
                xs: 40,
              },
              horizontal: {
                md: 10,
                sm: 10,
                xs: 10,
              },
            }}
          >
            <Col lg={4} md={6} sm={12}>
              <SmallCard
                Image={stepOne}
                title={'Lock ZEN Tokens'}
                desc={
                  'Stake your ZEN tokens for a chosen lockup period, receiving non-transferable sZEN tokens in return.'
                }
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <SmallCard
                Image={stepTwo}
                title={'Choose the Lockup Period'}
                desc={
                  'Select a lockup period ranging from 30 days to 4 years, with longer periods resulting in higher sZEN rewards.'
                }
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <SmallCard
                Image={stepThree}
                title={'Withdraw Assets'}
                desc={
                  'Once the lockup period expires, you can withdraw your assets immediately. However, rewards will no longer be received post-expiry.'
                }
              />
            </Col>
          </Row>
        </div>
        <div className={Style.contentSection}>
          <Row
            style={{ alignItems: 'center' }}
            columnGap={{
              vertical: {
                md: 40,
                sm: 40,
                xs: 40,
              },
              horizontal: {
                md: 22,
                sm: 22,
                xs: 22,
              },
            }}
          >
            <Col xl={6} md={12} className={Style.imageWrapper}>
              <img
                className={Style.contentImage}
                src={monthlySectionImage}
                alt="monthlySectionImage"
              />
            </Col>

            <Col xl={6} md={12}>
              <h2 className={Style.mainTitleM}>Monthly Rewards Cycle</h2>
              <div>
                <div ref={collapseOne} className={Style.limitList}>
                  <div
                    className={Style.title}
                    onClick={() => {
                      collapseOne?.current?.classList.toggle(Style.active)
                    }}
                  >
                    <h3>Accumulate Rewards</h3>
                    <div className={Style.arrow}>
                      <FiChevronDown />
                    </div>
                  </div>
                  <p className={Style.smallDescM}>
                    Staking rewards are collected on a monthly basis, based on
                    the Zenit Platform fee income and daily distribution rate of
                    ZEN staking incentives.
                  </p>
                </div>
                <Separator color="#6C6C6C" />
                <div ref={collapseTwo} className={Style.limitList}>
                  <div
                    className={Style.title}
                    onClick={() => {
                      collapseTwo?.current?.classList.toggle(Style.active)
                    }}
                  >
                    <h3>Calculate Your Share</h3>
                    <div className={Style.arrow}>
                      <FiChevronDown />
                    </div>
                  </div>
                  <p className={Style.smallDescM}>
                    The sZEN balance will depend on the amount of ZEN tokens
                    locked and the user's chosen lockup period. Stakers can lock
                    their tokens for a maximum of 4 years or a minimum of 30
                    days. Read our{' '}
                    <a
                      href="https://white-paper.zenit.world/products-and-services/staking/staking-lock-and-withdraw"
                      target="_blank"
                    >
                      {' '}
                      whitepaper
                    </a>{' '}
                    to understand how the reward calculations work.
                  </p>
                </div>
                <Separator color="#6C6C6C" />
                <div ref={collapseThree} className={Style.limitList}>
                  <div
                    className={Style.title}
                    onClick={() => {
                      collapseThree?.current?.classList.toggle(Style.active)
                    }}
                  >
                    <h3>Claim Rewards</h3>
                    <div className={Style.arrow}>
                      <FiChevronDown />
                    </div>
                  </div>
                  <p className={Style.smallDescM}>
                    The tokens will be available immediately after the lock
                    period, but they will not bring any more rewards
                    post-expiry.
                  </p>
                </div>
                <Separator color="#6C6C6C" />
              </div>
            </Col>
          </Row>
        </div>
        {/* unleashed Component */}
        <Unleashed />
      </div>
    </Container>
  )
}

const SmallCard = ({
  Image = stepOne,
  title = 'Lock ZEN Tokens',
  desc = 'Stake your ZEN tokens for a chosen lockup period, receiving non-transferable sZEN tokens in return.',
}) => {
  return (
    <div className={Style.smallCard}>
      <img className={Style.cardImage} src={Image} alt={Image} />
      <h3 className={Style.cardTitle}>{title}</h3>
      <p className={Style.smallDesc}>{desc}</p>
    </div>
  )
}

const Unleashed = () => {
  return (
    <div className={Style.colWrapper}>
      <div className={Style.sideWrapper}>
        <h3 className={Style.signUpTitle}>Unleash the Power of Staking</h3>
        <p className={Style.signUpDesc}>
          The ZEN Token, native to Zenit World, powers our platform and provides
          exclusive benefits to our users. By holding ZEN, you unlock a world of
          possibilities, including swapping, copy trading, staking, and
          community fees retribution. Join us on this exciting journey and
          explore the full potential of ZEN with Zenit World.
        </p>
        <div className={Style.buttonWrapper}>
          <a href="https://app.zenit.world/auth/register" target="_blank">
            <Button>Sign Up</Button>
          </a>
        </div>
      </div>
      <div className={Style.imageWrapper}>
        <img
          className={Style.contentImage}
          src={signUpImage}
          alt="signUpImage"
        />
      </div>
    </div>
  )
}

const UnluckThePotenttail = () => {
  return (
    <div className={Style.wrapperUnluck}>
      <Container>
        <div className={Style.unluck}>
          <h2 className={Style.mainTitle}>Unlock the Potential of Staking</h2>
          <div className={Style.cardWrapper}>
            <div className={Style.cardOne}>
              <h3 className={Style.cardTitle}>Reap Rewards</h3>
              <p className={Style.cardContent}>
                Your sZEN balance determines your share of staking rewards and
                account tier, granting access to exclusive benefits and lower
                fees..
              </p>
            </div>
            <div className={Style.cardRigh}>
              <div className={Style.cardTwo}>
                <h3 className={Style.cardRightTitle}>Lock and Earn</h3>
                <p className={Style.cardRightContent}>
                  ZEN holders can stake their tokens for a designated lockup
                  period to earn non-transferable sZEN tokens.
                </p>
              </div>
              <div className={Style.cardThree}>
                <h3 className={Style.cardRightTitle}>Monthly Redistribution</h3>
                <p className={Style.cardRightContent}>
                  Staking rewards are distributed monthly, with a percentage
                  sourced from the Zenit Platform Fee and the Incentive Program.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default Content

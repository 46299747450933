import React from 'react'
// Libraries
import { Container } from '@hybris-software/ui-kit'

// Images
import Seamless from '../../../../Assets/icons/swap/SeamlessExperience.svg'
import Optimal from '../../../../Assets/icons/swap/OptimalRates.svg'
import Flexible from '../../../../Assets/icons/swap/FlexibleTrading.svg'

// Style
import Style from './WhyChooseZenit.module.css'
const cardData = [
  {
    image: Seamless,
    title: 'Seamless Experience',
    content:
      'Enjoy a user-friendly interface for easy swapping and management.',
  },
  {
    image: Optimal,
    title: 'Optimal Rates',
    content:
      'Benefit from the liquidity of major exchanges for the best possible exchange rates.',
  },
  {
    image: Flexible,
    title: 'Flexible Trading',
    content: 'Execute market and limit orders for precise trading control.',
  },
]
const WhyChooseZenit = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.whyChooseZenit}>
          <h3 className={Style.mainTitle}>Why Choose Zenit’s Swap Feature?</h3>
          <div className={Style.cardWrapper}>
            <div className={Style.cardWrapper}>
              {cardData.map((card, index) => (
                <div key={index}>
                  <CardComponent
                    CardDesc={card.content}
                    CardImage={card.image}
                    CardTitle={card.title}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

const CardComponent = ({ CardImage, CardTitle, CardDesc }) => {
  return (
    <div className={Style.cardWrapper}>
      <div className={Style.cardComponent}>
        <img src={CardImage} alt="" className={Style.cardImage} />
        <h3 className={Style.cardTitle}>{CardTitle}</h3>
        <p className={Style.cardDesc}>{CardDesc}</p>
      </div>
    </div>
  )
}
export default WhyChooseZenit

import React from 'react'
// Libraries
import { Container, Button } from '@hybris-software/ui-kit'
// Images
import MainImage from '../../../../Assets/images/copyTradingCommunity.png'
// Style
import Style from './Community.module.css'
const Community = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.communityWrapper}>
          <div className={Style.textWrapper}>
            <h3 className={Style.title}>Be Part of a Global Community</h3>
            <p className={Style.content}>
              Unlock your  potential with Zenit. Join our network to connect,
              share, and learn from a community of like-minded people.
            </p>
            <a href="https://app.zenit.world/auth/register" target="_blank">
              <Button>Sign Up</Button>
            </a>
          </div>
          <div className={Style.imageWrapper}>
            <img src={MainImage} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Community

import React from 'react'

//  Library
import { Container } from '@hybris-software/ui-kit'
// Images
import Phone from '../../../../Assets/images/iPhone13.png'
// Style
import Style from './ReliabilitySection.module.css'
const ReliabilitySection = () => {
  return (
    <div className={Style.wrapper}>
      <div className={Style.reliabilitySection}>
        <Container>
          <div className={Style.titleWrapper}>
            <h3 className={Style.miniTitle}>
              Three Key safety features that set us apart
            </h3>
            <h4 className={Style.title}>Reliability Every Step of the Way</h4>
          </div>

          <div className={Style.contentWrapper}>
            <div className={Style.content}>
              <div className={Style.cardOneWrapper}>
                <div className={Style.cardOne}>
                  <h2 className={Style.titleCard}>Zenit Treasury Fund</h2>
                  <p className={Style.descCard}>
                    Allocation of 10% of the yield, we ensure compensation for
                    unforeseen events, fostering trust & security.
                  </p>
                </div>
                {/*  */}
                <div className={Style.cardTwo}>
                  <h2 className={Style.titleCardTwo}>Regulatory Compliance</h2>
                  <p className={Style.descCardTwo}>
                    Marinating industry standards, ensuring compliance with
                    regulations to uphold transparency & integrity in our
                    operations
                  </p>
                </div>
              </div>
              <div className={Style.reliabilityContent}>
                <div className={Style.phoneImage} />
              </div>
              <div className={Style.cardTwoWrapper}>
                <div className={Style.cardThree}>
                  <h2 className={Style.titleCardThree}>Robust Security</h2>
                  <p className={Style.descCardThree}>
                    Top-tier security measures, including advanced encryption &
                    multi-factor authentication.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default ReliabilitySection

import React from 'react'
// Libraries
import { Container, Button } from '@hybris-software/ui-kit'
// Images
import Paper from '../../../Assets/icons/PaperBox.svg'
// Style
import Style from './TopFooter.module.css'
const TopFooter = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.topFooter}>
          <div className={Style.textTitle}>
            <img src={Paper} alt="" />
            <p className={Style.text}>
              To get a comprehensive overview read our White Paper!
            </p>
          </div>
          <div className={Style.button}>
            <a
              href="https://docs.zenit.world/about/white-paper"
              target="_Blank"
              className={Style.button}
            >
              <Button>Learn More</Button>
            </a>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TopFooter

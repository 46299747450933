import React from 'react'
// Library
import { Container } from '@hybris-software/ui-kit'
// Images
import OurJ from '../../../../Assets/images/OUR JOURNEY.png'
// Style
import Style from './HeroSection.module.css'
const HeroSection = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.heroSection}>
          <div className={Style.titleWrapper}>
            <h3 className={Style.title}>Road map</h3>
            <p className={Style.desc}>
              Our detailed roadmap is filled with exciting news and innovative
              developments that will enhance our platform and benefit our users.
              Stay tuned as we progress towards achieving greater accessibility,
              efficiency, in the exciting world of crypto.
            </p>
          </div>
          <div className={Style.imageWrapper}>
            <img src={OurJ} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HeroSection

import React from "react";

// Libraries

// Images

// Icons

// Styles
import Style from "./Separator.module.css";

const Separator = ({isVertical= false, color = "#0C0C0C"}) => {
  return (
    <div className={isVertical ? Style.separatorVertical : Style.separatorHorizontal} style={{background: color}}></div>
  );
};

export default Separator;

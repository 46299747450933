import React from "react";

// Icons
// import { ReactComponent as Loader } from "../Assets/icons/loader.svg";

// Styles
import Theme from "../Assets/css/Theme.module.css";

const theme = {
  row: {
    columnGap: {
      horizontal: {
        xs: 20,
        sm: 20,
        md: 20,
        lg: 20,
        xl: 20,
        xxl: 20,
      },
      vertical: {
        xs: 20,
        sm: 20,
        md: 20,
        lg: 20,
        xl: 20,
        xxl: 20,
      },
    },
  },
  button: {
    buttonClassName: Theme.buttonStyle,
    buttonDisabledClassName: Theme.buttonStyleDisabled,
    buttonSecondaryClassName: Theme.buttonSecondary,
    // loader: React.createElement(Loader, { height: 25 }),
  },
  inputField: {
    baseClassName: Theme.inputFieldStyle,
    errorClassName: Theme.inputFieldErrorStyle,
    successClassName: Theme.inputFieldSuccessStyle,
    errorMessageClassName: Theme.inputFieldErrorMessageStyle,
    labelClassName: Theme.inputFieldLabelStyle,
  },
  container: {
    containerClassName: Theme.containerStyle,
  },
};

export default theme;

import React, { useState, useEffect } from 'react'
// Components
import HeroSectoin from './Components/HeroSectoin/HeroSectoin'
import MissionAndVision from './Components/MissionAndVision/MissionAndVision'
import OurValues from './Components/OurValues/OurValues'
import EnterEmailSection from '../../Components/UIKit/EnterEmailSection/EnterEmailSection'
import Loader from '../../Components/Advanced/Loader/Loader'
//
import { useLocation } from 'react-router-dom'
import getPageTitle from '../../Components/Advanced/Router/Utility'
// Style
import Style from './AboutUsView.module.css'
const AboutUsView = () => {
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()

  if (loading) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'unset'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = getPageTitle(pathname)
    setTimeout(() => {
      setLoading(false)
    }, 2100)
  }, [])
  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}
      <HeroSectoin />
      <MissionAndVision />
      <OurValues />
      {/* <EnterEmailSection /> */}
    </>
  )
}

export default AboutUsView

import React, { useState, useEffect } from 'react'
import EnterEmailSection from '../../Components/UIKit/EnterEmailSection/EnterEmailSection'
import TopFooter from '../../Components/UIKit/TopFooter/TopFooter'
import Loader from '../../Components/Advanced/Loader/Loader'
//
import { useLocation } from 'react-router-dom'
import getPageTitle from '../../Components/Advanced/Router/Utility'
// Style
import Style from './StakingView.module.css'
import HeroSection from './Components/HeroSection/HeroSection'
import Content from './Components/Content/Content'

const StakingView = () => {
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()

  if (loading) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'unset'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = getPageTitle(pathname)
    setTimeout(() => {
      setLoading(false)
    }, 2100)
  }, [])
  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}
      <section className={Style.heroSection}>
        <HeroSection />
      </section>
      <section className={Style.content}>
        <Content />
      </section>
      <section>{/* <EnterEmailSection /> */}</section>
      <section>
        <TopFooter />
      </section>
    </>
  )
}

export default StakingView

import React from 'react'

//Utils
import classNames from '../../../../Utils/classNames'

//Images
import leftTop from '../../../../Assets/images/FaitWalletView/FaitWalletOneLeftTopImage.png'
import rightTop from '../../../../Assets/images/FaitWalletView/FaitWalletOneRightTopImage.png'
import leftBottom from '../../../../Assets/images/FaitWalletView/FaitWalletOneLeftBottomImage.png'
import rightBottom from '../../../../Assets/images/FaitWalletView/FaitWalletOneRightBottomImage.png'

// Style
import Style from './One.module.css'
import Theme from '../../../../Assets/css/Theme.module.css'
import { Container } from '@hybris-software/ui-kit'

const One = () => {
  return (
    <Container>
      <div className={Style.One}>
        <div className={Style.firstRow}>
          <div className={Style.firstLeft}>
            <div className={Style.firstLeftClipped}>
              <h4 className={Style.oneTitle}>
                Effortless Deposits & Withdrawals
              </h4>
              <p className={Style.OneBody}>
                Deposit and withdraw funds in USD or EUR directly from your bank
                account to your Zenit account.
              </p>
            </div>
            <img
              className={Style.leftTopIcon}
              src={leftTop}
              alt="leftTopIcon"
            />
          </div>
          <div className={Style.firstRight}>
            <div className={Style.firstRightClipped}>
              <h4 className={Style.oneTitle}>Streamlined Conversion</h4>
              <p className={Style.OneBody}>
                Convert your fiat currency into USDT within the platform,
                eliminating the need for external exchanges.
              </p>
            </div>
            <img
              className={Style.rightTopIcon}
              src={rightTop}
              alt="rightTopIcon"
            />
          </div>
        </div>
        <div className={Style.secondRow}>
          <div className={Style.secondRowContent}>
            <h6>The Fiat Wallet</h6>
            <h2>Bridging the Gap</h2>
          </div>
        </div>
        <div className={Style.thirdRow}>
          <div className={Style.thirdLeft}>
            <div className={Style.thirdLeftClipped}>
              <h4 className={Style.oneTitle}>Tracking and Reporting</h4>
              <p className={Style.OneBody}>
                Access transaction history and comprehensive reports for full
                visibility of your financial activities.
              </p>
            </div>
            <img
              className={Style.leftBottomIcon}
              src={leftBottom}
              alt="leftBottomIcon"
            />
          </div>
          <div className={Style.thirdRight}>
            <div className={Style.thirdRightClipped}>
              <h4 className={Style.oneTitle}>Compliance and Security</h4>
              <p className={Style.OneBody}>
                Adhering to regulatory requirements and implementing
                industry-leading security measures to protect your funds.
              </p>
            </div>
            <img
              className={Style.rightBottomIcon}
              src={rightBottom}
              alt="rightBottomIcon"
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default One

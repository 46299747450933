import React from 'react'
// Library
import { Container } from '@hybris-software/ui-kit'

// Images
import One from '../../../../Assets/icons/BGVAboutUsCardOne.svg'
import Two from '../../../../Assets/icons/BGVAboutUsCardTwo.svg'
import Three from '../../../../Assets/icons/BGVAboutUsCardThree.svg'
import Four from '../../../../Assets/icons/BGVAboutUsCardFour.svg'
import Zenit from '../../../../Assets/images/zenitLogo3d.png'

// Style
import Style from './OurValues.module.css'
const OurValues = () => {
  return (
    <div className={Style.wrapper}>
      <div className={Style.ourValues}>
        <Container>
          <h3 className={Style.mainTitle}>Our Values</h3>
          <div className={Style.ourValuesCards}>
            <div className={Style.cardsTop}>
              <ValuesCard
                image={One}
                title="Simplicity"
                content="We prioritize simplicity in our platform to ensure a user-friendly experience for all."
              />
              <ValuesCard
                image={Two}
                title="Innovation "
                content="Forward-thinking solutions that drive our strategic approach."
              />
            </div>
            <div className={Style.logoWrapper}>
              <img src={Zenit} alt="" />
            </div>
            <div className={Style.cardsBottom}>
              <ValuesCard
                image={Three}
                title="Transparency "
                content="Clear and trustworthy information to make informed decisions in the crypto world."
              />
              <ValuesCard
                image={Four}
                title="Equal Opportunities"
                content="Zenit is committed to empowerment and leveling the playing field for everyone."
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

const ValuesCard = ({ title, content, image }) => {
  return (
    <div className={Style.cardWrapper}>
      <div
        className={Style.valuesCard}
        style={{ backgroundImage: `url(${image})` }}
      >
        <h3 className={Style.title}>{title}</h3>
        <p className={Style.desc}>{content}</p>
      </div>
    </div>
  )
}
export default OurValues

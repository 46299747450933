import React, { useState, useEffect } from 'react'

// Components
import Hero from './Components/Hero/Hero'
import One from './Components/One/One'
import Three from './Components/Three/Three'
import EnterEmailSection from '../../Components/UIKit/EnterEmailSection/EnterEmailSection'
import TopFooter from '../../Components/UIKit/TopFooter/TopFooter'
import Loader from '../../Components/Advanced/Loader/Loader'
//
import { useLocation } from 'react-router-dom'
import getPageTitle from '../../Components/Advanced/Router/Utility'
// Images
import background from '../../Assets/images/FaitWalletView/FaitWalletFourBGImage.png'
// Style
import Style from './FaitWalletView.module.css'

const FaitWalletView = () => {
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()

  if (loading) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'unset'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = getPageTitle(pathname)
    setTimeout(() => {
      setLoading(false)
    }, 2100)
  }, [])
  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}
      <section className={Style.heroSection}>
        <Hero />
      </section>
      <section className={Style.sectionOne}>
        <One />
      </section>
      <section className={Style.sectionTwo}></section>
      <section className={Style.sectionThree}>
        <Three />
      </section>
      {/* <section className={Style.sectionFour}>
        <EnterEmailSection
          title={'A New Era Of Trading'}
          description={
            "Embrace the future of trading with Zenit's Fiat Wallet. Seamlessly connect traditional and digital currencies for a comprehensive and user-friendly trading experience. "
          }
          imageUrl={background}
        />
      </section> */}

      <section>
        <TopFooter />
      </section>
    </>
  )
}

export default FaitWalletView

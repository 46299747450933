import React from 'react'
// Libraries
import { Button, Container } from '@hybris-software/ui-kit'
// Images
import HeroBg from '../../../../Assets/images/heroBg.png'
// Icons
import { BiBookAlt } from 'react-icons/bi'
// Style
import Style from './HeroSection.module.css'
const HeroSection = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.heroSection}>
          <div className={Style.textWrapper}>
            <h3 className={Style.title}>Unlock the Power of Your Crypto</h3>
            <p className={Style.discreption}>
              Experience high-level trading made accessible to all. Join Zenit
              World Platform for expert-guided crypto trading services,
              featuring seamless swapping and a rewarding tier system full of
              benefits.
            </p>
            <div className={Style.buttonsWrapper}>
              <a
                href="https://app.zenit.world/auth/register"
                target="_blank"
                rel="noreferrer"
              >
                <Button>Get Started</Button>
              </a>
              <a
                href="https://docs.zenit.world/about/white-paper"
                target="_blank"
                rel="noreferrer"
              >
                <p className={Style.videoButton}>
                  <span className={Style.iconWrapper}>
                    <BiBookAlt className={Style.icon} />
                  </span>
                  Read Our Whitepaper
                </p>
              </a>
            </div>
          </div>
          <div className={Style.imageWrapper}>
            <img src={HeroBg} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HeroSection

import React from 'react'
// Libraries
import { Container } from '@hybris-software/ui-kit'

// Images
import Friendly from '../../../../Assets/icons/trading/Friendly.svg'
import Automatic from '../../../../Assets/icons/trading/Automatic.svg'
import Leverage from '../../../../Assets/icons/trading/Leverage.svg'

// Style
import Style from './WhyWeTrading.module.css'
const cardData = [
  {
    image: Friendly,
    title: 'User Friendly',
    content: 'Copy and manage trades effortlessly',
  },
  {
    image: Automatic,
    title: 'Automatic',
    content: 'Watch as your portfolio mirrors the trades you copy in real time',
  },
  {
    image: Leverage,
    title: 'Leverage Expertise',
    content: 'Benefit from experienced traders',
  },
]
const WhyWeTrading = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.whyWeTrading}>
          <h3 className={Style.mainTitle}>Why Copy Trading?</h3>
          <div className={Style.cardWrapper}>
            <div className={Style.cardWrapper}>
              {cardData.map((card, index) => (
                <div key={index}>
                  <CardComponent
                    CardDesc={card.content}
                    CardImage={card.image}
                    CardTitle={card.title}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

const CardComponent = ({ CardImage, CardTitle, CardDesc }) => {
  return (
    <div className={Style.cardWrapper}>
      <div className={Style.cardComponent}>
        <img src={CardImage} alt="" className={Style.cardImage} />
        <h3 className={Style.cardTitle}>{CardTitle}</h3>
        <p className={Style.cardDesc}>{CardDesc}</p>
      </div>
    </div>
  )
}
export default WhyWeTrading

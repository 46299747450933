import React from 'react'

//Libraries
import { Button, Col, Row } from '@hybris-software/ui-kit'

//images
import FaitWalletThreeCardOneImage from '../../../../../Assets/images/FaitWalletView/FaitWalletThreeCardOneImage.png'
import angularCard from '../../../../../Assets/images/FaitWalletView/FaitWalletThreeCardAngularImage.png.png'

//Utils

// Style
import Style from './Card.module.css'

const Card = ({
  number = '01',
  desc = 'Deposit & Withdraw Fiat Currency',
  icon = FaitWalletThreeCardOneImage,
}) => {
  return (
    <div className={Style.card}>
      <img className={Style.cardAngular} src={angularCard} alt="angularCard" />
      <div className={Style.cardWrapper}>
        <div className={Style.textWrapper}>
          <h4 className={Style.cardNumber}>{number}</h4>
          <p className={Style.cardDesc}>{desc}</p>
        </div>
        <div className={Style.imageWrapper}>
          <img className={Style.cardImage} src={icon} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Card

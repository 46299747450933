import { Container, useWindowSize } from '@hybris-software/ui-kit'
import React from 'react'
// Images fullCircle.png
import MainImage from '../../../../Assets/images/copyTradingHowitWorks.png'
import FullMainImage from '../../../../Assets/images/fullCircle.png'

// Style
import Style from './HowItWorks.module.css'

const data = [
  {
    number: '01.',
    title: 'Create an Account',
    content:
      'Browse and select a trader to copy based on their history, performance, and risk level.',
  },
  {
    number: '02.',
    title: 'Deposit Funds',
    content: 'Add funds to your account using crypto or fiat currency.',
  },
  {
    number: '03.',
    title: 'Set Preferences',
    content: 'Allocate an amount for copy trading and set other preferences.',
  },
  {
    number: '04.',
    title: 'Activate Copy Trading',
    content: 'Enable the feature once preferences are set.',
  },
  {
    number: '05.',
    title: 'Track Performance',
    content:
      'Monitor gains and losses using performance-tracking tools to refine future strategies.',
  },
]
const HowItWorks = () => {
  const { width } = useWindowSize()

  return (
    <>
      {width > 2000 ? (
        <LargScreen />
      ) : (
        <div className={Style.wrapper}>
          <div className={Style.imageWrapper}>
            <img src={MainImage} alt="" />
          </div>
          <Container>
            <div className={Style.howItWorks}>
              <h3 className={Style.mainTitle}>How it Works?</h3>
              <h4 className={Style.subTitle}>
                Our Copy Trading Feature is designed with a sleek intuitive
                interface.
              </h4>
              <div className={Style.cardListWrapper}>
                {data.map((data, index) => (
                  <div key={index} className={Style.cardMap}>
                    <CardList
                      number={data.number}
                      title={data.title}
                      content={data.content}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

const CardList = ({ number, title, content }) => {
  return (
    <div className={Style.cardWrapper}>
      <p className={Style.number}>{number}</p>
      <h3 className={Style.title}> {title} </h3>
      <p className={Style.content}>{content}</p>
    </div>
  )
}

const LargScreen = () => {
  return (
    <Container>
      <div className={Style.wrapperFull}>
        <div className={Style.imageFullWrapper}>
          <img src={FullMainImage} alt="" className={Style.imageFull} />
        </div>
        <div className={Style.howItWorks}>
          <h3 className={Style.mainTitle}>How it Works?</h3>
          <h4 className={Style.subTitle}>
            Our Copy Trading Feature is designed with a sleek intuitive
            interface.
          </h4>
          <div className={Style.cardListWrapper}>
            {data.map((data, index) => (
              <div key={index} className={Style.cardMap}>
                <CardList
                  number={data.number}
                  title={data.title}
                  content={data.content}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  )
}
export default HowItWorks

import React from 'react'
// Library
import { Container, useWindowSize } from '@hybris-software/ui-kit'

// Images
import Line from '../../../../Assets/icons/TimeLineLine.svg'
import Circle from '../../../../Assets/icons/TimeLineCircle.svg'
import DarkLine from '../../../../Assets/icons/TimeLineDarkLine.svg'
import DarkCircle from '../../../../Assets/icons/TimeLineDarkCircle.svg'

// Style
import Style from './TimeLine.module.css'
const TimeLine = () => {
  const windowSize = useWindowSize()
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.timeLine}>
          {windowSize.width > 1024 ? (
            <div className={Style.firstPart}>
              <h3 className={Style.dateActive}>2022</h3>
              {/* right 1 */}
              <div className={Style.partLeftWrapper}>
                <div className={Style.partLeft}>
                  <div className={Style.titleWrapper}>
                    <h3 className={Style.title}>Q1,2022</h3>
                  </div>
                  <div className={Style.imagesLineWrapper}>
                    <img src={Line} alt="" className={Style.imagesLine} />
                    <img src={Circle} alt="" className={Style.imagesCircle} />
                  </div>
                  <div className={Style.cardComponentWrapper}>
                    <CardTimeLine
                      one={'Core Team Selection'}
                      two={'Research and Development'}
                      three={'Legal Analysis OK'}
                      four={'Legal Opinion OK'}
                      five={'Closing Debug Strategy OK'}
                    />
                  </div>
                </div>
              </div>
              {/* left 2 */}
              <div className={Style.partLeftWrapper}>
                <div className={Style.partLeft}>
                  <div className={Style.cardComponentWrapper}>
                    <CardTimeLine
                      one={'White Paper'}
                      two={'Open Company for Issuance'}
                      three={'Start Platform Development'}
                    />
                  </div>

                  <div className={Style.imagesLineWrapper}>
                    <img src={Line} alt="" className={Style.imagesLine} />
                    <img src={Circle} alt="" className={Style.imagesCircle} />
                  </div>
                  <div className={Style.titleWrapper}>
                    <h3 className={Style.titleE}>Q2,2022</h3>
                  </div>
                </div>
              </div>
              {/* right 3*/}
              <div className={Style.partLeftWrapper}>
                <div className={Style.partLeft}>
                  <div className={Style.titleWrapper}>
                    <h3 className={Style.title}>Q3,2022</h3>
                  </div>
                  <div className={Style.imagesLineWrapper}>
                    <img src={Line} alt="" className={Style.imagesLine} />
                    <img src={Circle} alt="" className={Style.imagesCircle} />
                  </div>
                  <div className={Style.cardComponentWrapper}>
                    <CardTimeLine
                      one={'Event Launch with LYOPAY'}
                      two={'Starting Private Stage'}
                      three={'Seed Rounds'}
                    />
                  </div>
                </div>
              </div>
              {/* left 4*/}
              <div className={Style.partLeftWrapperDark}>
                <div className={Style.partLeft}>
                  <div className={Style.cardComponentWrapper}>
                    <CardTimeLine
                      one={
                        'Open registration and the waiting list for Zenit.World'
                      }
                    />
                  </div>

                  <div className={Style.imagesLineWrapper}>
                    <img src={DarkLine} alt="" className={Style.imagesLine} />
                    <img
                      src={DarkCircle}
                      alt=""
                      className={Style.imagesCircle}
                    />
                  </div>
                  <div className={Style.titleWrapper}>
                    <h3 className={Style.titleE}>Q4,2022</h3>
                  </div>
                </div>
              </div>
              {/* 2023 5*/}
              <div className={Style.partLeftWrapperDarkDate}>
                <div className={Style.partLeft}>
                  <div className={Style.titleWrapper}></div>
                  <div className={Style.imagesLineWrapper}>
                    <img src={DarkLine} alt="" className={Style.imagesLineSm} />
                    <h3 className={Style.date}>2023</h3>
                  </div>
                  <div className={Style.cardComponentWrapper}></div>
                </div>
              </div>
              {/* right 6*/}
              <div className={Style.partLeftWrapper}>
                <div className={Style.partLeft}>
                  <div className={Style.titleWrapper}>
                    <h3 className={Style.title}>Q1,2022</h3>
                  </div>
                  <div className={Style.imagesLineWrapper}>
                    <img src={DarkLine} alt="" className={Style.imagesLine} />
                    <img
                      src={DarkCircle}
                      alt=""
                      className={Style.imagesCircle}
                    />
                  </div>
                  <div className={Style.cardComponentWrapper}>
                    <CardTimeLine
                      one={'Development MVP Product'}
                      two={' Private Stage'}
                    />
                  </div>
                </div>
              </div>
              {/* left 7*/}
              <div className={Style.partLeftWrapperDark}>
                <div className={Style.partLeft}>
                  <div className={Style.cardComponentWrapper}>
                    <CardTimeLine
                      one={'Private Stage'}
                      two={'Copy Trading Area V1'}
                      three={'Wallet Area V1'}
                      four={'Swap V1'}
                      five={'Open Copy trading for customers'}
                      six={'Launching MPV for Whitelist Customers'}
                    />
                  </div>

                  <div className={Style.imagesLineWrapper}>
                    <img src={DarkLine} alt="" className={Style.imagesLine} />
                    <img
                      src={DarkCircle}
                      alt=""
                      className={Style.imagesCircle}
                    />
                  </div>
                  <div className={Style.titleWrapper}>
                    <h3 className={Style.titleE}>Q2,2023</h3>
                  </div>
                </div>
              </div>

              {/* right 8*/}
              <div className={Style.partLeftWrapperDark}>
                <div className={Style.partLeft}>
                  <div className={Style.titleWrapper}>
                    <h3 className={Style.title}>Q3,2023</h3>
                  </div>
                  <div className={Style.imagesLineWrapper}>
                    <img src={DarkLine} alt="" className={Style.imagesLine} />
                    <img
                      src={DarkCircle}
                      alt=""
                      className={Style.imagesCircle}
                    />
                  </div>
                  <div className={Style.cardComponentWrapper}>
                    <CardTimeLine
                      one={'Limit Swap V1'}
                      two={'Staking Service V1'}
                      three={'FIAT on-ramp payment system V1'}
                      four={'VASP Licence'}
                    />
                  </div>
                </div>
              </div>

              {/* left 9*/}
              <div className={Style.partLeftWrapperDark}>
                <div className={Style.partLeft}>
                  <div className={Style.cardComponentWrapper}>
                    <CardTimeLine
                      one={'Listing ZEN'}
                      two={'Public Rounds'}
                      three={'ZEN staking program'}
                      four={'Tier Level system'}
                      five={'Open Deposit on Zenit.World'}
                      six={'15 December TGE'}
                    />
                  </div>

                  <div className={Style.imagesLineWrapper}>
                    <img src={DarkLine} alt="" className={Style.imagesLine} />
                    <img
                      src={DarkCircle}
                      alt=""
                      className={Style.imagesCircle}
                    />
                  </div>
                  <div className={Style.titleWrapper}>
                    <h3 className={Style.titleE}>Q4,2023</h3>
                  </div>
                </div>
              </div>

              {/* Line 10*/}
              <div className={Style.partLeftWrapperDark}>
                <div className={Style.partLeft}>
                  <div className={Style.cardComponentWrapper}></div>

                  <div className={Style.imagesLineWrapper}>
                    <img src={DarkLine} alt="" className={Style.imagesLine} />
                  </div>
                  <div className={Style.titleWrapper}></div>
                </div>
              </div>
            </div>
          ) : (
            <div className={Style.mobileWrapper}>
              {/* 2022 */}
              <h3 className={Style.dateActive}>2022</h3>
              {/* 1 */}
              <div className={Style.cardMobileWrapper}>
                <div className={Style.imagesLineMobileWrapper}>
                  <img src={Line} alt="" className={Style.imagesLineM} />
                  <img src={Circle} alt="" className={Style.imagesCircle} />
                </div>
                <div className={Style.titleWrapper}>
                  <h3 className={Style.title}>Q1,2022</h3>
                </div>
                <CardTimeLine
                  one={'Core Team Selection'}
                  two={'Research and Development'}
                  three={'Legal Analysis OK'}
                  four={'Legal Opinion OK'}
                  five={'Closing Debug Strategy OK'}
                />
              </div>
              {/* 2 */}
              <div className={Style.cardMobileWrapper}>
                <div className={Style.imagesLineMobileWrapper}>
                  <img src={Line} alt="" className={Style.imagesLineM} />
                  <img src={Circle} alt="" className={Style.imagesCircle} />
                </div>
                <div className={Style.titleWrapper}>
                  <h3 className={Style.title}>Q2,2022</h3>
                </div>
                <CardTimeLine
                  one={'White Paper'}
                  two={'Open Company for Issuance'}
                  three={'Start Platform Development'}
                />
              </div>
              {/* 3 */}
              <div className={Style.cardMobileWrapper}>
                <div className={Style.imagesLineMobileWrapper}>
                  <img src={Line} alt="" className={Style.imagesLineM} />
                  <img src={Circle} alt="" className={Style.imagesCircle} />
                </div>
                <div className={Style.titleWrapper}>
                  <h3 className={Style.title}>Q3,2022</h3>
                </div>
                <CardTimeLine
                  one={'Event Launch with LYOPAY'}
                  two={'Starting Private Stage'}
                  three={'Seed Rounds'}
                />
              </div>
              {/* 4 */}
              <div className={Style.cardMobileWrapper}>
                <div className={Style.imagesLineMobileWrapper}>
                  <img src={Line} alt="" className={Style.imagesLineM} />
                  <img src={Circle} alt="" className={Style.imagesCircle} />
                </div>
                <div className={Style.titleWrapper}>
                  <h3 className={Style.title}>Q4,2022</h3>
                </div>
                <CardTimeLine
                  one={'Open registration and the waiting list for Zenit.World'}
                />
              </div>
              {/* 5 2023*/}
              <div className={Style.cardMobileWrapper}>
                <div className={Style.imagesLineWrapper}>
                  <img src={DarkLine} alt="" className={Style.imagesLineSm} />
                  <h3 className={Style.date}>2023</h3>
                </div>
              </div>
              {/* 6 */}
              <div className={Style.cardMobileWrapper}>
                <div className={Style.imagesLineWrapper}>
                  <img src={DarkLine} alt="" className={Style.imagesLineM} />
                  <img
                    src={DarkCircle}
                    alt=""
                    className={Style.imagesCircleM}
                  />
                </div>
                <div className={Style.titleWrapper}>
                  <h3 className={Style.title}>Q1,2022</h3>
                </div>
                <CardTimeLine
                  one={'Development MVP Product'}
                  two={' Private Stage'}
                />
              </div>
              {/*7 */}
              <div className={Style.cardMobileWrapper}>
                <div className={Style.imagesLineWrapper}>
                  <img src={DarkLine} alt="" className={Style.imagesLineM} />
                  <img
                    src={DarkCircle}
                    alt=""
                    className={Style.imagesCircleM}
                  />
                </div>
                <div className={Style.titleWrapper}>
                  <h3 className={Style.titleE}>Q2,2023</h3>
                </div>
                <CardTimeLine
                  one={'Private Stage'}
                  two={'Copy Trading Area V1'}
                  three={'Wallet Area V1'}
                  four={'Swap V1'}
                  five={'Open Copy trading for customers'}
                  six={'Launching MPV for Whitelist Customers'}
                />
              </div>
              {/* 8*/}
              <div className={Style.cardMobileWrapper}>
                <div className={Style.imagesLineWrapper}>
                  <img src={DarkLine} alt="" className={Style.imagesLineM} />
                  <img
                    src={DarkCircle}
                    alt=""
                    className={Style.imagesCircleM}
                  />
                  <div className={Style.titleWrapper}>
                    <h3 className={Style.title}>Q3,2023</h3>
                  </div>
                </div>
                <CardTimeLine
                  one={'Limit Swap V1'}
                  two={'Staking Service V1'}
                  three={'FIAT on-ramp payment system V1'}
                  four={'VASP Licence'}
                />
              </div>
              {/* 9 */}
              <div className={Style.cardMobileWrapper}>
                <div className={Style.imagesLineWrapper}>
                  <img src={DarkLine} alt="" className={Style.imagesLineM} />
                  <img
                    src={DarkCircle}
                    alt=""
                    className={Style.imagesCircleM}
                  />
                </div>
                <div className={Style.titleWrapper}>
                  <h3 className={Style.titleE}>Q4,2023</h3>
                </div>
                <CardTimeLine
                  one={'Listing ZEN'}
                  two={'Public Rounds'}
                  three={'ZEN staking program'}
                  four={'Tier Level system'}
                  five={'Open Deposit on Zenit.World'}
                  six={'15 December TGE'}
                />
              </div>
              {/* 10*/}
              <div className={Style.cardMobileWrapper}>
                <div className={Style.imagesLineWrapper}>
                  <img src={DarkLine} alt="" className={Style.imagesLineM} />
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

const CardTimeLine = ({ one, two, three, four, five, six }) => {
  return (
    <div className={Style.cardWrapper}>
      <div className={Style.cardTimeLine}>
        {one && (
          <div className={Style.first}>
            <ul>
              <li
                className={Style.singleListFirst}
                style={{ whiteSpace: 'nowrap' }}
              >
                {one}
              </li>
              {two && <li className={Style.singleList}>{two}</li>}
            </ul>
          </div>
        )}
        {three && (
          <div className={Style.first}>
            <ul>
              <li className={Style.singleList}>{three}</li>
              {four && <li className={Style.singleList}>{four}</li>}
            </ul>
          </div>
        )}
        {five && (
          <div className={Style.first}>
            <ul>
              <li className={Style.singleList}>{five}</li>
              {six && <li className={Style.singleList}>{six}</li>}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default TimeLine

import React, { useState, useEffect } from 'react'
// Library
import HeroSection from './Components/HeroSection/HeroSection'
import EnterEmailSection from '../../Components/UIKit/EnterEmailSection/EnterEmailSection'
import Loader from '../../Components/Advanced/Loader/Loader'
//
import { useLocation } from 'react-router-dom'
import getPageTitle from '../../Components/Advanced/Router/Utility'
// Style
import Style from './RoadMapView.module.css'
import TimeLine from './Components/TimeLine/TimeLine'
const RoadMapView = () => {
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()

  if (loading) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'unset'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = getPageTitle(pathname)
    setTimeout(() => {
      setLoading(false)
    }, 2100)
  }, [])
  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}
      <HeroSection />
      <TimeLine />
      {/* <EnterEmailSection /> */}
    </>
  )
}

export default RoadMapView

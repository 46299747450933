import React, { useState, useEffect } from 'react'
import Loader from '../../Components/Advanced/Loader/Loader'
//
import { useLocation } from 'react-router-dom'
import getPageTitle from '../../Components/Advanced/Router/Utility'
// Images
import NotFoundImage from '../../Assets/images/404.png'
import { Button, Container } from '@hybris-software/ui-kit'
import { Link } from 'react-router-dom'
// Styles
import Style from './NotFoundView.module.css'

const NotFoundView = () => {
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()

  if (loading) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'unset'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = getPageTitle(pathname)
    setTimeout(() => {
      setLoading(false)
    }, 2100)
  }, [])
  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}
      <div className={Style.wrapper}>
        <div className={Style.notFoundView}>
          <img src={NotFoundImage} alt="" className={Style.notImage} />
          <h2 className={Style.title}>Page not found</h2>
          <p className={Style.content}>
            Sorry we can’t found the page that you’re looking for
          </p>
          <Link to={'/'}>
            <Button>Back to Homepage</Button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default NotFoundView

import { Container } from '@hybris-software/ui-kit'
import React from 'react'
// Images
import MainImage from '../../../../Assets/images/HowitWorksSwap.png'
// Style
import Style from './HowItWorks.module.css'
const HowItWorks = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.howItWorks}>
          <h3 className={Style.mainTitle}>How it Works?</h3>
          <h4 className={Style.subTitle}>
            Discover the simplicity of Zenit's Swap feature:
          </h4>
          <div className={Style.componentWrapper}>
            <div className={Style.listWrapper}>
              <div className={Style.list}>
                <p className={Style.number}>01.</p>
                <h3 className={Style.title}>Deposit Funds</h3>
              </div>
              <div className={Style.list}>
                <p className={Style.number}>02.</p>
                <h3 className={Style.title}>Select Cryptocurrency</h3>
              </div>
              <div className={Style.list}>
                <p className={Style.number}>03.</p>
                <h3 className={Style.title}>Set order Details</h3>
              </div>
              <div className={Style.list}>
                <p className={Style.number}>04.</p>
                <h3 className={Style.title}>Confirm & Execute</h3>
              </div>
              <div className={Style.list}>
                <p className={Style.number}>05.</p>
                <h3 className={Style.title}>Track Transaction History</h3>
              </div>
            </div>
            <div className={Style.imageWrapper}>
              <img src={MainImage} alt="" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HowItWorks

import React from 'react'

// Assets
import LoaderGif from '../../../../Assets/videos/loader.gif'

//Styles
import Style from './LoaderDefaultImage.module.css'

const LoaderDefaultImage = () => {
  return (
    <div className={Style.loaderBox}>
      <img src={LoaderGif} alt="loader" />
    </div>
  )
}

export default LoaderDefaultImage

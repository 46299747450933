import React from 'react'

//Libraries
import { Col, Container, Row } from '@hybris-software/ui-kit'

//images
import zenTokenHeroRightSection from '../../../../Assets/images/ZenTokenView/zenTokenHeroRightSection.png'

//Utils
import classNames from '../../../../Utils/classNames'
// Style
import Style from './Empowering.module.css'
import Separator from '../../../../Components/UIKit/Separator/Separator'

const Empowering = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.empowering}>
          <div className={Style.cardWrapper}>
            <h2 className={Style.empoweringHeading}>
              ZEN Token Empowering the Future
            </h2>
          </div>
          <div className={Style.cardWrapper}>
            <Separator />
            <div className={Style.ListItem}>
              <h3 className={Style.itemTitle}>Deflationary Asset</h3>
              <p className={Style.itemBody}>
                ZEN represents the growth of Zenit, generating rewards and
                capturing the essence of our project's success.
              </p>
            </div>
            <Separator />
            <div className={Style.ListItem}>
              <h3 className={Style.itemTitle}>Access All Services</h3>
              <p className={Style.itemBody}>
                With ZEN, you gain full access to the range of services and
                benefits offered by Zenit World.
              </p>
            </div>
            <Separator />
            <div className={Style.ListItem}>
              <h3 className={Style.itemTitle}>Total Supply and Distribution</h3>
              <p className={Style.itemBody}>
                The total supply of ZEN is 153,000,000 tokens, carefully
                distributed to maintain its uniqueness and value.
              </p>
            </div>
            <Separator />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Empowering

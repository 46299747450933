import React from 'react'

//Components
import Separator from '../../../../Components/UIKit/Separator/Separator'

//Libraries
import { Col, Container, Row } from '@hybris-software/ui-kit'

//images
import zenTokenContentImageOne from '../../../../Assets/images/ZenTokenView/zenTokenContentImageOne.png'
import zenTokenContentImageTwo from '../../../../Assets/images/ZenTokenView/zenTokenContentImageTwo.png'

//Icon
import { FiArrowUpRight } from 'react-icons/fi'

//Utils
import classNames from '../../../../Utils/classNames'

// Style
import Style from './Content.module.css'
import Theme from '../../../../Assets/css/Theme.module.css'

import { AiFillAccountBook } from 'react-icons/ai'

const Content = () => {
  return (
    <div className={Style.content}>
      <Container>
        <div className={Style.sectionOne}>
          <ExperienceThePower />
        </div>
        <div className={Style.sectionTwo}>
          <UnluckThePotenttail />
        </div>
        <div className={Style.sectionThree}>
          <ExclusiveBenefit />
        </div>
        <div className={Theme.firstWrapper}>
          <Separator />
          <div className={Style.signUpContainer}>
            <StartYourCrypto />
          </div>
          <Separator />
        </div>
      </Container>
    </div>
  )
}

const ExperienceThePower = () => {
  return (
    <div className={Style.wrapperExperience}>
      <Container>
        <div className={Style.expreience}>
          <div className={Style.wrapperText}>
            <h3 className={Style.title}>Experience the Power of ZEN</h3>
            <p className={Style.desc}>
              The ZEN Token, native to Zenit World, powers our platform and
              provides exclusive benefits to our users. By holding ZEN, you
              unlock a world of possibilities, including swapping, copy trading,
              staking, and community fees retribution. Join us on this exciting
              journey and explore the full potential of ZEN with Zenit World.
            </p>
          </div>
          <div className={Style.wrapperImage}>
            <img
              className={Style.expreienceImage}
              src={zenTokenContentImageOne}
              alt="zenTokenContentImageOne"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

const UnluckThePotenttail = () => {
  return (
    <div className={Style.wrapperUnluck}>
      <Container>
        <div className={Style.unluck}>
          <h2 className={Style.mainTitle}>Unlock the Potential of Staking</h2>
          <div className={Style.cardWrapper}>
            <div className={Style.cardOne}>
              <h3 className={Style.cardTitle}>Proportional Rewards</h3>
              <p className={Style.cardContent}>
                The longer the lockup period, the greater the amount of sZEN
                received, determining your share of staking rewards and account
                tier..
              </p>
            </div>
            <div className={Style.cardRigh}>
              <div className={Style.cardTwo}>
                <h3 className={Style.cardRightTitle}>Lock and Earn</h3>
                <p className={Style.cardRightContent}>
                  ZEN holders can stake their tokens for a designated lockup
                  period to earn non-transferable sZEN tokens.
                </p>
              </div>
              <div className={Style.cardThree}>
                <h3 className={Style.cardRightTitle}>Monthly Redistribution</h3>
                <p className={Style.cardRightContent}>
                  Staking rewards are distributed monthly, with a percentage
                  sourced from the Zenit Platform Fee and the Incentive Program.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

const ExclusiveBenefit = () => {
  return (
    <div className={Style.wrapperExperience}>
      <Container>
        <div className={Style.expreience}>
          <div className={Style.wrapperImage}>
            <img
              className={Style.exclusiveImage}
              src={zenTokenContentImageTwo}
              alt="zenTokenContentImageOne"
            />
          </div>
          <div className={Style.wrapperText}>
            <h3 className={Style.title}>Exclusive Benefits with sZEN</h3>
            <p className={Style.desc}>
              Enjoy the advantages of sZEN, the non-transferable token obtained
              through staking ZEN. Holding sZEN provides access to exclusive
              benefits and lower fees on the Zenit platform. Maximize your
              trading experience and reap the rewards of being a part of the
              Zenit community.
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}

const StartYourCrypto = () => {
  return (
    <div>
      <Container>
        <div className={Style.start}>
          <div className={Style.rightWrapper}>
            <h3 className={Style.titleCrypto}>
              Start Your Crypto Journey Today
            </h3>
            <a
              href="https://app.zenit.world/auth/register"
              target="_blank"
              className={Style.signinLink}
            >
              <p className={Style.signinLinkText}>SIGN UP</p>
              <FiArrowUpRight className={Style.signinLinkIcon} />
            </a>
          </div>
          <div className={Style.leftWrapper}>
            <p>
              Embrace the ZEN JourneyStep into the world of ZEN Tokenomics and
              seize the opportunities it offers. Join Zenit World and witness
              the power of ZEN as it drives your success in the cryptocurrency
              market.
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default Content

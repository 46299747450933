import React from 'react'
//Libraries
import { Col, Container, Row } from '@hybris-software/ui-kit'

//Utils
import classNames from '../../../../Utils/classNames'

//Images
import mainImage from '../../../../Assets/images/FaitWalletView/FaitWalletThreeMainImage.png'
import secondImage from '../../../../Assets/images/FaitWalletView/FaitWalletThreeSecondImage.png'

import cardOne from '../../../../Assets/images/FaitWalletView/FaitWalletThreeCardOneImage.png'
import cardTwo from '../../../../Assets/images/FaitWalletView/FaitWalletThreeCardTwoImage.png'
import cardThree from '../../../../Assets/images/FaitWalletView/FaitWalletThreeCardThreeImage.png'
import cardFour from '../../../../Assets/images/FaitWalletView/FaitWalletThreeCardFourImage.png'

// Style
import Style from './Three.module.css'
import Card from './Components/Card'

const Three = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.experienceWrapper}>
          <Experience />
        </div>

        <div className={Style.cardWrapper}>
          <div className={Style.cardFullWrapper}>
            <div className={Style.cardComponentWrapper}>
              <Card
                desc={'Deposit & Withdraw Fiat Currency'}
                number={'01.'}
                icon={cardOne}
              />
            </div>
            <div className={Style.cardComponentWrapper}>
              <Card
                desc={'Convert Fiat Currency to USDT'}
                number={'02.'}
                icon={cardTwo}
              />
            </div>
            <div className={Style.cardComponentWrapper}>
              <Card
                desc={'Convert USDT  to FIAT Currency'}
                number={'03.'}
                icon={cardTwo}
              />
            </div>
            <div className={Style.cardComponentWrapper}>
              <Card
                desc={'Track Transactions & Access Reports'}
                number={'04.'}
                icon={cardThree}
              />
            </div>
            <div className={Style.cardComponentWrapper}>
              <Card
                desc={'Enjoy Seamless Integration with Other Platform Features'}
                number={'05.'}
                icon={cardFour}
              />
            </div>
          </div>
        </div>
        <div className={Style.phoneWrapper}>
          <Catering />
        </div>
      </Container>
    </div>
  )
}

const Catering = () => {
  return (
    <div className={Style.cateringWrapper}>
      <Container>
        <div className={Style.catering}>
          <div className={Style.textWrapper}>
            <h2 className={Style.title}>Experience the Power of ZEN</h2>
            <p className={Style.content}>
              The ZEN Token, native to Zenit World, powers our platform and
              provides exclusive benefits to our users. By holding ZEN, you
              unlock a world of possibilities, including swapping, copy trading,
              staking, and community fees retribution. Join us on this exciting
              journey and explore the full potential of ZEN with Zenit World.
            </p>
          </div>
          <div className={Style.ImageWrapper}>
            <img
              className={Style.phoneImage}
              src={mainImage}
              alt="zenTokenContentImageOne"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

const Experience = () => {
  return (
    <div className={Style.experienceWrapper}>
      <div className={Style.experience}>
        <div className={Style.infoWrapper}>
          <h6 className={Style.smallTitle}>Simple & Integrated</h6>
          <h2 className={Style.sectionOneSecondTitle}>
            Experience the ease of Zenit’s Fiat Wallet
          </h2>
        </div>
        <div className={Style.imageWrapperExp}>
          <img
            className={Style.sectionOneSecondImage}
            src={secondImage}
            alt="secondImage"
          />
        </div>
      </div>
    </div>
  )
}

export default Three

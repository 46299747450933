import React from 'react'

//Libraries
import { Button, Col, Container, Row } from '@hybris-software/ui-kit'

//images
import stakingHeroRightSection from '../../../../Assets/images/Staking/stakingHeroRightSection.png'

// Style
import Style from './HeroSection.module.css'

const HeroSection = () => {
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.rowWrapper}>
          <div className={Style.textContainer}>
            <h6 className={Style.miniTitle}>
              Stake ZEN to get and collect new sZEN
            </h6>
            <h2 className={Style.title}>
              enjoy exclusive rewards and benefits with Zenit World's staking
              protocol.
            </h2>
            <p className={Style.content}>
              Maximize your ZEN holdings! With Zenit's Staking feature, you can
              stake your tokens to earn non-transferable sZEN tokens
            </p>
            <a href="https://app.zenit.world/auth/register" target="_blank">
              <Button>Get Started</Button>
            </a>
          </div>
          <div>
            <img
              className={Style.heroImage}
              src={stakingHeroRightSection}
              alt="zenToken"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HeroSection

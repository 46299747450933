import React from 'react'
// Crypto Icons
import BitCoin from '../../../../Assets/icons/crypto/Bitcoin.svg'
import ZENToken from '../../../../Assets/icons/crypto/Zentoken.svg'
import EthereumETH from '../../../../Assets/icons/crypto/EthereumETH.svg'
import sZENToken from '../../../../Assets/icons/crypto/sZENtoken.svg'
import TRX from '../../../../Assets/icons/crypto/TRX.svg'
import TetherUSDT from '../../../../Assets/icons/crypto/TetherUSDT.svg'
import USDC from '../../../../Assets/icons/crypto/USDC.svg'
import WETH from '../../../../Assets/icons/crypto/WETH.svg'
import BinanceBUSD from '../../../../Assets/icons/crypto/BinanceBUSD.svg'
import LYO from '../../../../Assets/icons/crypto/LYO.svg'
import MATIC from '../../../../Assets/icons/crypto/MATIC.svg'
import WBTC from '../../../../Assets/icons/crypto/WBTC.svg'
import LFI from '../../../../Assets/icons/crypto/LFI.svg'

// Style

import Style from './CryptoSliders.module.css'
const cryptoData = [
  {
    id: 1,
    name: 'Bitcoin BTC',
    icon: BitCoin,
  },
  {
    id: 2,
    name: 'ZEN Token',
    icon: ZENToken,
  },
  {
    id: 3,
    name: 'Ethereum ETH',
    icon: EthereumETH,
  },
  {
    id: 4,
    name: 'sZEN Token',
    icon: sZENToken,
  },
  {
    id: 5,
    name: 'TRX',
    icon: TRX,
  },
  {
    id: 6,
    name: 'Tether USDT',
    icon: TetherUSDT,
  },
  {
    id: 7,
    name: 'USDC',
    icon: USDC,
  },
  {
    id: 8,
    name: 'WETH',
    icon: WETH,
  },
  {
    id: 9,
    name: 'Binance BUSD',
    icon: BinanceBUSD,
  },
  {
    id: 10,
    name: 'LYO',
    icon: LYO,
  },
  {
    id: 11,
    name: 'MATIC',
    icon: MATIC,
  },
  {
    id: 12,
    name: 'Bitcoin BTC',
    icon: BitCoin,
  },
  {
    id: 13,
    name: 'WBTC',
    icon: WBTC,
  },
  {
    id: 14,
    name: 'ZEN Token',
    icon: ZENToken,
  },
  {
    id: 15,
    name: 'LFI',
    icon: LFI,
  },
]

const CryptoSliders = () => {
  return (
    <div className={Style.wrapper}>
      <div className={Style.cryptoSliders}>
        <div className={Style.scroll}>
          <div className={Style.mScroll}>
            {cryptoData.map((crypto) => (
              <span key={crypto.id}>
                <SingleCrypto image={crypto.icon} title={crypto.name} />
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={Style.cryptoSliders}>
        <div className={Style.scroll}>
          <div className={Style.lScroll}>
            {cryptoData.map((crypto) => (
              <span key={crypto.id}>
                <SingleCrypto image={crypto.icon} title={crypto.name} />
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const SingleCrypto = ({ image, title }) => {
  return (
    <div className={Style.wrapperSingle}>
      <div className={Style.SingleCrypto}>
        <img src={image} alt="" className={Style.image} />
        <p className={Style.title}>{title}</p>
      </div>
    </div>
  )
}
export default CryptoSliders

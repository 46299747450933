import React from 'react'
// Library
import { Button, Container } from '@hybris-software/ui-kit'

// Images
import MainImage from '../../../../Assets/images/mainImageCopyTrading.png'
import COPYTRADINGFOOTER from '../../../../Assets/images/COPYTRADINGFOOTER.png'

// Style
import Style from './HeroSection.module.css'

const HeroSection = () => {
  return (
    <div className={Style.wrapper}>
      <div className={Style.backgroundImages} />
      <Container>
        <div className={Style.heroSection}>
          <div className={Style.textWrapper}>
            <h4 className={Style.miniTitle}>
              With Zenit's Copy Trading feature
            </h4>
            <h3 className={Style.title}>
              you can easily follow and replicate the trades of experienced
              professional traders.
            </h3>
            <p className={Style.content}>
              With Zenit's Copy Trading feature, you can easily follow and
              replicate the trades of experienced professional traders.
            </p>
            <a href="https://app.zenit.world/auth/register" target="_blank">
              <Button>Get Started</Button>
            </a>
          </div>
          <div className={Style.imageWrapper}>
            <img src={MainImage} alt="" />
          </div>
        </div>
        <img src={COPYTRADINGFOOTER} alt="" className={Style.footerImage} />
      </Container>
    </div>
  )
}

export default HeroSection

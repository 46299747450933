import React, { useState, useEffect } from 'react'

//Components
import Hero from './Components/Hero/Hero'
import Empowering from './Components/Empowering/Empowering'
import Content from './Components/Content/Content'
import EnterEmailSection from '../../Components/UIKit/EnterEmailSection/EnterEmailSection'
import TopFooter from '../../Components/UIKit/TopFooter/TopFooter'
import Loader from '../../Components/Advanced/Loader/Loader'
//
import { useLocation } from 'react-router-dom'
import getPageTitle from '../../Components/Advanced/Router/Utility'
// Style
import Style from './ZenTokenView.module.css'

const ZenTokenView = () => {
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()

  if (loading) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'unset'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = getPageTitle(pathname)
    setTimeout(() => {
      setLoading(false)
    }, 2100)
  }, [])
  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}
      <section className={Style.heroSection}>
        <Hero />
      </section>
      <section className={Style.empowering}>
        <Empowering />
      </section>
      <section className={Style.zenInfo}>
        <Content />
      </section>
      {/* <EnterEmailSection /> */}
      <TopFooter />
    </>
  )
}

export default ZenTokenView

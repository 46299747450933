import React, { useState } from 'react'
// Library
import { Container, useWindowSize } from '@hybris-software/ui-kit'
import { Link } from 'react-router-dom'

// Images
import First from '../../../../Assets/images/homeCopyTrading.png'
import Secound from '../../../../Assets/images/homeSwap.png'
import Third from '../../../../Assets/images/homeFiatWallet.png'
import Fourth from '../../../../Assets/images/homeStaking.png'
import Fifth from '../../../../Assets/images/homeZENToken.png'

// Icon
import { BsArrowRight } from 'react-icons/bs'

// Style
import Style from './InnovativeProduct.module.css'

const tradeingList = [
  {
    id: 1,
    title: 'Copy Trading',
    describe:
      'Boost your trading knowledge by following and replicating experienced traders.',
    Link: 'https://docs.zenit.world/products/copy-trading',
    image: First,
    classNameImage: 'imageWrapper',
  },
  {
    id: 2,
    title: 'Swap',
    describe:
      'Instantly convert between cryptocurrencies with our user-friendly swap functionality.',
    Link: 'https://docs.zenit.world/products/swap',
    image: Secound,
    classNameImage: 'imageWrapper',
  },
  {
    id: 3,
    title: 'Fiat Wallet',
    describe:
      'Securely manage your fiat currencies with our user-friendly wallet.',
    Link: 'https://docs.zenit.world/products/fiat-wallet',
    image: Third,
    classNameImage: 'imageWrapper',
  },
  {
    id: 4,
    title: 'Staking',
    describe:
      'Diversify your portfolio with Zenits staking options, whether you are looking for long-term or short-term investment strategies.',
    Link: 'https://docs.zenit.world/products/staking',
    image: Fourth,
    classNameImage: 'imageWrapperTop',
  },
  {
    id: 5,
    title: 'ZEN Token',
    describe:
      'Become a part of the global financial revolution and embrace the future of finance with our native token ZEN.',
    Link: 'https://docs.zenit.world/products/zen-and-szen',
    image: Fifth,
    classNameImage: 'imageWrapperTop',
  },
]

const InnovativeProduct = () => {
  const windowSize = useWindowSize()
  const [activeIndex, setActiveIndex] = useState(0)

  const handleTabClick = (index) => {
    setActiveIndex(index)
  }
  return (
    <div className={Style.wrapper}>
      <Container>
        <div className={Style.innovativeProduct}>
          <div className={Style.titleWrapper}>
            <h3 className={Style.miniTitle}>
              Explore our Innovative Product suite
            </h3>
            <h4 className={Style.title}>Copy Trading, Staking and Beyond</h4>
          </div>
          {windowSize.width > 1024 ? (
            <div className={Style.tabsTitlesWrapper}>
              <div className={Style.tabsTitlesRows}>
                {tradeingList.map((tradeing, index) => {
                  let classImage = tradeing.classNameImage
                  return (
                    <div
                      className={
                        index === activeIndex
                          ? Style.tableWrapperActive
                          : Style.tableWrapper
                      }
                      key={tradeing.id}
                    >
                      <div className={Style.leftSide}>
                        <h3
                          onClick={() => handleTabClick(index)}
                          className={
                            index === activeIndex
                              ? Style.activeTitle
                              : Style.normalTitle
                          }
                        >
                          {tradeing.title}
                        </h3>
                      </div>
                      {index === activeIndex && (
                        <div className={Style.rightSide}>
                          <div className={Style.descSide}>
                            <p className={Style.tabsDesc}>
                              {tradeing.describe}
                            </p>
                            {tradeing.Link && (
                              <a
                                href={tradeing.Link}
                                className={Style.tradeLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                See how it works
                                <BsArrowRight className={Style.tradeLinkIcon} />
                              </a>
                            )}
                          </div>
                          {tradeing.image && (
                            <div className={Style[classImage]}>
                              <img src={tradeing.image} alt="" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <div className={Style.mobileListCard}>
              {tradeingList.map((tradeing, index) => (
                <MobileProducts
                  key={index}
                  title={tradeing.title}
                  content={tradeing.describe}
                  link={tradeing.Link}
                  image={tradeing.image}
                />
              ))}
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

const MobileProducts = ({ title, content, link, image }) => {
  return (
    <div className={Style.mobileWrapper}>
      <div className={Style.mobileStyle}>
        {image && (
          <div className={Style.imageMobileWrapper}>
            <img src={image} alt="" />
          </div>
        )}
        <h3 className={Style.mobileTitle}> {title} </h3>
        <p className={Style.mobileDesc}>{content}</p>
        {link && (
          <Link className={Style.tradeLink} to={link}>
            See how it works
            <BsArrowRight className={Style.tradeLinkIcon} />
          </Link>
        )}
      </div>
    </div>
  )
}

export default InnovativeProduct
